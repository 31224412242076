import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import get from 'lodash/get';
import Box from '../Box';
import CustomAvatar from '../../atoms/Avatar';
import Picture from '../../atoms/Picture';
import DctSvgIcon from '../../atoms/Svg';
import useStyles from './style';

const IconWithText = props => {
  const {
    className,
    iconType = 'FallbackCheckIcon', // fallback check icon
    avatarClassName,
    children,
    isTextThenIcon,
    iconWithCircle,
    iconImage,
    isAnimate,
    showIcon = true,
    ...other
  } = props;

  const TextThenIcon = () => {
    return (
      <Box className={clsx(classes.root, classes.textThenIcon, className)}>
        {children}
        {
          showIcon &&
          <CustomAvatar className={avatarClassName} {...other}>
            {get(iconImage, 'value.src', '').length > 1 ? ( // Sometimes only '/' comes in src, hence handled that case here also
              <Picture
                media={{
                  image: iconImage,
                  mobileImage: iconImage,
                  renditionNeeded: false,
                  fallbackBackgroundNeeded: false,
                }}
                isParalax={false}
              />
            ) : isAnimate ? (
              <span className="animateIcon">
                <DctSvgIcon name={iconType} />
              </span>
            ) : (
              <DctSvgIcon name={iconType} />
            )}
          </CustomAvatar>
        }
      </Box>
    );
  };

  const IconThenText = () => {
    return (
      <Box className={clsx(classes.root, classes.iconThenText, className)}>
        {
          showIcon &&
          <CustomAvatar className={avatarClassName} {...other}>
            {get(iconImage, 'value.src', '').length > 1 ? (
              <Picture
                media={{
                  image: iconImage,
                  mobileImage: iconImage,
                  renditionNeeded: false,
                  fallbackBackgroundNeeded: false,
                }}
                isParalax={false}
              />
            ) : isAnimate ? (
              <span className="animateIcon">
                <DctSvgIcon name={iconType} />
              </span>
            ) : (
              <DctSvgIcon name={iconType} />
            )}
          </CustomAvatar>
        }
        {children}
      </Box>
    );
  };

  const classes = useStyles(iconWithCircle);
  return isTextThenIcon ? TextThenIcon() : IconThenText();
};

IconWithText.propTypes = {
  className: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isTextThenIcon: PropTypes.bool,
  iconWithCircle: PropTypes.bool,
  iconImage: PropTypes.object,
};

IconWithText.defaultTypes = {
  className: '',
  isTextThenIcon: false,
  iconWithCircle: true,
  isAnimate: false,
  iconImage: '',
};

export default IconWithText;
