import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

export const ScrollToTop = (type, marginTop = 0) => {
  if (window) {
    const supportsNativeSmoothScroll =
      'scrollBehavior' in document.documentElement.style;
    if (supportsNativeSmoothScroll) {
      window.scrollTo({ top: marginTop, behavior: type });
    } else {
      gsap.registerPlugin(ScrollToPlugin);
      smoothScrollToTop()
    }
  }
};

function smoothScrollToTop() {
  gsap.to(window, {
    scrollTo: { y: 0 },
    duration: 1.5,
    ease: "power1.inOut",
  });
}
