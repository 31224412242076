import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    '&.footer-fx': {
      '& .main-footer': {
        [theme.breakpoints.up('lg')]: {
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: '100%',
          zIndex: 1,
        },
      },
    },
    '&.AdFooter': {
      '& .main-footer': {
        backgroundColor: '#9F28C1',
        '& .quickLinks': {
          '& h2': {
            fontSize: pxToRem(22),
            lineHeight: pxToRem(32),
            [theme.breakpoints.down('md')]: {
              fontSize: pxToRem(20),
            },
          },
          '& .scrollArrow': {
            [theme.breakpoints.down('md')]: {
              top: pxToRem(36)
            },
          }
        },
        '& .follow-us-link-item': {
          width: pxToRem(48),
          height: pxToRem(48),
          [theme.breakpoints.down('lg')]: {
            width: pxToRem(40),
            height: pxToRem(40),
          },
        },
        '& .footer-logo': {
          maxWidth: "100%",
          width: pxToRem(236),
          [theme.breakpoints.down('lg')]: {
            width: pxToRem(142),
          },
        },
        '& .newsLetter': {
          position: 'relative',
          '& .btn-txt': {
            "& .primaryCTA": {
              [theme.breakpoints.down('lg')]: {
                lineHeight: `${pxToRem(24)} !important`,
                fontSize: `${pxToRem(16)} !important`,
                fontWeight: 700,
              },
            },
          },
          '&::before': {
            position: 'absolute',
            ...theme.mixins.left(pxToRem(-64)),
            top: 0,
            backgroundColor: '#FFFFFF33',
            width: '1px',
            height: '100%',
            content: "''"
          },
          '& h2': {
            fontSize: pxToRem(22),
            lineHeight: pxToRem(32),
            marginBottom: pxToRem(25),
            [theme.breakpoints.down('md')]: {
              fontSize: pxToRem(20),
              marginBottom: pxToRem(0),
              paddingBottom: 0
            },
          },
          '& button': {
            backgroundColor: '#009086'
          }
        },
        '& .downloadApp': {
          '& h2': {
            fontSize: pxToRem(22),
            lineHeight: pxToRem(32),
            [theme.breakpoints.down('md')]: {
              fontSize: pxToRem(20),
            },
          },
          '& .divider': {
            backgroundColor: '#B253CD',
            [theme.breakpoints.down('md')]: {
              backgroundColor: 'rgba(255,255,255, 0.2)',
            },
          }
        },
        '& .followUs': {
          '& h2': {
            fontSize: pxToRem(22),
            lineHeight: pxToRem(32),
            [theme.breakpoints.down('md')]: {
              fontSize: pxToRem(20),
            },
          }
        }
      },
    }
  },
  rootInner: {
    backgroundColor: theme.mixins.RussianViolet(),
    color: theme.mixins.White(),
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  upperSection: {
    marginTop: theme.spacing(7),
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(5),
    },
  },
  quickLinks: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4.5),
      '& .quick-link-desktop-view': {
        display: 'none',
      },
    },
    '& ul': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },

  heading: {
    marginTop: '0',
    paddingBottom: theme.spacing(3),
  },
  newsletter: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      ...theme.mixins.paddingRight(80),
      marginBottom: 0,
    },
    '& h2:not(.follow-us)': {
      paddingBottom: theme.spacing(1),
    },
    '& .sectionSlideDown': {
      transform: 'translateY(0)',
    },
  },
  pr0: {
    paddingRight: '0',
  },
  subscribeDescription: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  emailTextBox: {
    backgroundColor: theme.mixins.Grey(500),
    fontSize: fontSize.s14Rem,
    borderRadius: borderRadius.b3Rem,
    '& input': {
      color: theme.mixins.White(),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      fontFamily: theme.custom.fontFamily.primaryLight,
    },
    '& input::placeholder': {
      color: theme.mixins.White(),
      fontSize: fontSize.s16Rem,
      marginTop: 0,
    },
    '& svg': {
      color: theme.mixins.White(),
      ...theme.mixins.marginRight(theme.spacing(1)),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(45),
      width: '100%',
    },
  },
  inputLabel: {
    display: 'none',
  },
  downloadApp: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(6),
      marginTop: 0,
    },
    '& img.noRendition': {
      width: 'auto',
      height: 'auto',
    },
  },
  downloadAppHeading: {
    marginTop: '0',
    paddingBottom: theme.spacing(3),
  },
  downloadAppLogo: {
    height: pxToRem(42),
    '&:not(:last-child)': {
      ...theme.mixins.paddingRight(theme.spacing(4.5)),
    },
  },
  scrollTop: {
    border: '3px solid white',
    borderRadius: borderRadius.circle,
    position: 'absolute',
    ...theme.mixins.right(3),
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.right(pxToRem(12)),
    },
  },
  scrollTopHover: {
    '&:hover': {
      backgroundColor: theme.mixins.White(),
      color: theme.mixins.Black(),
    },
    '&:active': {
      backgroundColor: theme.mixins.White(),
      color: theme.mixins.Black(),
    },
  },
  listItem: {
    '&.MuiListItem-root': {
      paddingBottom: theme.spacing(2),
      paddingLeft: 0,
      paddingTop: 0,
      fontFamily: theme.custom.fontFamily.primaryBold,
      textAlign: theme.mixins.textLeft(),

      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  linkItem: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '& span': {
      borderBottom: '1px solid transparent',
      '&:hover': {
        borderBottomColor: `${theme.mixins.White()}`,
      },
    },
  },
  followUsLinkItem: {
    textDecoration: 'none',
    color: 'inherit',
    ...theme.mixins.marginRight(theme.spacing(2)),
    marginBottom: theme.spacing(2),
    height: pxToRem(40),
    width: pxToRem(40),
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.marginRight(theme.spacing(3)),
      marginBottom: theme.spacing(3),
    },
    '& img': {
      backgroundColor: theme.mixins.White(0),
      borderRadius: borderRadius.circle,
    },
  },
  socialMediaIcons: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  secondaryLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
    '& .secondaryLink': {
      color: theme.mixins.White(),
      borderBottom: '1px solid transparent',
      '&:hover': {
        borderBottomColor: `${theme.mixins.White()}`,
      },
    },

    '& .MuiListItem-root': {
      paddingTop: '0',
      paddingBottom: theme.spacing(2),
      marginRight: '1rem',
      width: 'auto',

      [theme.breakpoints.up('lg')]: {
        width: 'auto',
        ...theme.mixins.marginLeft(theme.spacing(6)),
      },
    },
  },
  copyright: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '&.detailPage': {
      // we need this static value because our sticky
      // bar is transform to mobile view from 769
      [theme.breakpoints.down('769')]: {
        marginBottom: theme.spacing(15),
      },
    },
    '& .copy-txt': {
      [theme.breakpoints.up('lg')]: {
        textAlign: theme.mixins.textRight(),
      },
    },
    '& img': {
      maxWidth: pxToRem(142),
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(5),
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: pxToRem(163),
      },
    },
  },
  ScrollToTopContainer: {
    marginTop: theme.spacing(-7),
  },
  divider: {
    backgroundColor: theme.mixins.White(200),
  },
  footerQuickLinks: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  icpLicense: {
    color: theme.mixins.White(),
    borderBottom: '1px solid transparent',
    '&:hover': {
      borderBottom: '1px solid white',
    },
  },
}));
export default useStyles;
